<template>
<div class="calibration">
    <button @click="calibration">
        Kalibracja alarmu
    </button>
</div>
</template>

<script>
export default {
    name: 'Calibration',
    computed: {
        isCalibrationMode() {
            return this.$store.state.settings.alarm[0] == this.$store.state.mode.CALIBRATION;
        }
    },
    watch: {
        isCalibrationMode() {
            if (this.isCalibrationMode) {
                this.modal();
            } else {
                this.$swal.close();
            }
        }
    },
    methods: {
        calibration() {
            this.$mqtt.publish('mode', this.$store.state.mode.CALIBRATION.toString() + ';' + this.$store.state.auth + ';' + (new Date()).getTime());
        },

        modal() {
            this.$swal({
                title: 'Kalibracja alarmu',
                text: 'Alarm przeszedł w tryb kalibracji',
                icon: 'warning',
                confirmButtonText: 'Wyłącz tryb kalibracji',
                allowOutsideClick: false,
                preConfirm: () => {
                    this.$mqtt.publish('mode', this.$store.state.mode.INACTIVE.toString() + ';' + this.$store.state.auth + ';' + (new Date()).getTime());
                    return false;
                }
            });
        }
    }
}
</script>