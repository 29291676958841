<template>
<div class="view view--authorization">
    <div class="grid">
        <div>
            <img src="@/assets/images/logotype-uniti.png" />
        </div>
        <h1>
            Garden <sup>2.5</sup>
        </h1>
        <form class="form" @submit.prevent="authorize">
            <div class="form__row">
                <div class="input input--select">
                    <label for="user">
                        <i class="icon-username"></i>
                    </label>
                    <select name="user" id="user" v-model="user">
                        <option v-for="user in this.$store.state.users" :value="user.id">{{ user.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form__row">
                <div class="input input--password">
                    <label for="password">
                        <i class="icon-key"></i>
                    </label>
                    <input type="password" name="password" id="password" v-model="password" />
                </div>
            </div>
            <div class="form__row">
                <button type="submit" class="button">
                    Zaloguj
                </button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    name: 'Authorization',
    data() {
        return {
            user: 0,
            password: '',
            hash: '4e7a20843e2f67b213946ac43a43c4d2ea3a66a3'
        }
    },
    mounted() {
        if (this.$store.state.auth !== -1) {
            this.$router.push({name: 'Default'});
        }
    },
    methods: {
        authorize() {
            const hashedPassword = this.$CryptoJS.SHA1(this.password).toString();

            if (this.hash === hashedPassword) {
                this.$store.state.auth = this.user;

                if (!this.$store.state.auth == -1) {
                    return;
                }

                localStorage.setItem('auth', this.$store.state.auth);
                this.$router.push({name: 'Default'});

                return;
            }

            this.password = '';

            this.$swal({
                icon: 'error',
                title: 'Błąd autoryzacji',
                text: 'Podane hasło jest nieprawidłowe'
            });
        }
    }
}
</script>