import { createStore } from 'vuex'

export default createStore({
  state: {
      auth: -1,
      users: [
          {id: 0, name: 'Grzegorz Banaś'},
          {id: 1, name: 'Małgorzata Banaś'},
          {id: 2, name: 'Anna Żabka'},
          {id: 3, name: 'Aniela Żabka'},
          {id: 4, name: 'Helenka'}
      ],
      mode: {
          'INACTIVE': 0,
          'ACTIVE': 1,
          'CALIBRATION': 2,
          'TEST': 3
      },
      uptime: 0,
      settings: {
          alarm: [0, null, null, 0],
          devices: [0, 0, 0, 0]
      }
  },
  mutations: {
  },
  actions: {
      load(context, data) {
          let settings = data.toString().split(';');

          settings = settings.map((item, index) => {
              return index == 2 ? item : parseInt(item);
          });

          this.state.settings.alarm = [
              settings[0], settings[1], settings[2], settings[3]
          ];

          if (settings[3] >= 30) {
              this.state.settings.alarm[3] = 5;
          } else if (settings[3] >= 15) {
              this.state.settings.alarm[3] = 4;
          } else if (settings[3] >= 10) {
              this.state.settings.alarm[3] = 3;
          } else if (settings[3] >= 5) {
              this.state.settings.alarm[3] = 2;
          } else if (settings[3] > 0) {
              this.state.settings.alarm[3] = 1;
          } else {
              this.state.settings.alarm[3] = 0;
          }

          this.state.settings.devices = [
              settings[4], settings[5], settings[6], settings[7]
          ];

          this.state.uptime = settings[8];
      }
  },
  modules: {
  }
});