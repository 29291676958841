<template>
<div class="alarm" v-bind:class="{'active': this.$store.state.settings.alarm[0] == this.$store.state.mode.ACTIVE}">
    <div class="grid alarm__grid">
        <h1>Alarm</h1>
        <div class="alarm__columns">
            <div class="alarm__box wide">
                <h2 class="alarm__box__value">
                    <i class="icon-antenna"></i>
                    <SignalStrength />
                </h2>
                <h4>
                    Połączenie GSM
                </h4>
            </div>
        </div>
        <div class="alarm__columns">
            <div class="alarm__box">
                <h3>
                    <i class="icon-calendar"></i>
                    {{ lastEventDate ? lastEventDate : '-' }}
                </h3>
                <h4>
                    Ostatnie zmiany
                </h4>
            </div>
            <div class="alarm__box">
                <h3 class="alarm__box__value">
                    <i class="icon-person"></i>
                    {{ lastEventAuthor ? lastEventAuthor : '-' }}
                </h3>
                <h4>
                    Przez
                </h4>
            </div>
        </div>
        <div class="alarm__columns">
            <button class="button alarm__button" v-bind:class="{'button--red': this.$store.state.settings.alarm[0] == this.$store.state.mode.ACTIVE}" @click="alarm">
                <template v-if="this.$store.state.settings.alarm[0] == this.$store.state.mode.ACTIVE">Wyłącz</template>
                <template v-if="this.$store.state.settings.alarm[0] != this.$store.state.mode.ACTIVE">Włącz</template>
                <div class="loading" v-if="loading"><i></i></div>
            </button>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import SignalStrength from './SignalStrength';

export default {
    name: 'Alarm',
    components: { SignalStrength },
    mounted() {
        this.$mqtt.subscribe('update-mode');
    },
    computed: {
        lastEventDate() {
            if (this.$store.state.settings.alarm[2] == null) {
                return;
            }
            return moment(parseInt(this.$store.state.settings.alarm[2])).format('DD.MM.YYYY HH:mm:ss');
        },

        lastEventAuthor() {
            if (this.$store.state.settings.alarm[1] == null) {
                return;
            }

            return this.$store.state.users[this.$store.state.settings.alarm[1]].name;
        }
    },
    data() {
        return {
            loading: false
        }
    },
    mqtt: {
        'update-mode'(value) {
            const   splitted = value.toString().split(';'),
                    mode = parseInt(splitted[0]);

            this.$store.state.settings.alarm[0] = mode;
            this.$store.state.settings.alarm[1] = parseInt(splitted[1]);
            this.$store.state.settings.alarm[2] = splitted[2];
            this.loading = false;
        }
    },
    methods: {
        alarm() {
            if (this.loading) {
                return false;
            }

            if (this.$store.state.settings.alarm[0] == this.$store.state.mode.TEST) {
                this.$store.state.settings.alarm[0] = this.$store.state.mode.INACTIVE;
            }
            this.$mqtt.publish('mode', (~~!this.$store.state.settings.alarm[0] + ';' + this.$store.state.auth + ';' + (new Date()).getTime()).toString());
            this.loading = true;
        }
    }
}
</script>