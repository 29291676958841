import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';
import Authorization from '../views/Authorization.vue'
import Default from '../views/Default.vue'

const routes = [{
        path: '/',
        name: 'Default',
        component: Default,
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/authorization',
        name: 'Authorization',
        component: Authorization
    }]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    store.state.auth = localStorage.getItem('auth') ?? -1;
    if (to.matched.some(record => record.meta.requiresAuth) && store.state.auth == -1) {
        next({
            name: 'Authorization',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
});

export default router
