<template>
<div class="view view--default">
    <Alarm ref="alarm" />
    <!-- <Temperature ref="temperature" /> -->
    <Devices ref="controls" />
</div>
</template>

<script>
import Alarm from '@/components/Alarm';
// import Temperature from '@/components/Temperature';
import Devices from '@/components/Devices';

export default {
    name: 'Default',
    components: {
        Devices,
        // Temperature,
        Alarm
    },
    mounted () {
        /*
        window.addEventListener('load', () => {
            this.temperatureMargin();
            window.addEventListener('resize', this.temperatureMargin);
        });
        */
    },
    data() {},
    methods: {
        /*
        temperatureMargin() {
            this.$refs.temperature.$el.style.marginTop = (this.$refs.alarm.$el.clientHeight + 25) + 'px';
        }
        */
    }
}
</script>