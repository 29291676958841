<template>
    <div class="test">
        <button @click="test">
            Test alarmu
        </button>
    </div>
</template>

<script>
export default {
    name: 'Test',
    computed: {
        isTestMode() {
            return this.$store.state.settings.alarm[0] == this.$store.state.mode.TEST;
        }
    },
    watch: {
        isTestMode() {
            if (this.isTestMode) {
                this.alarmTestModal();
            } else {
                this.$swal.close();
            }
        }
    },
    methods: {
        test() { this.$mqtt.publish('mode', this.$store.state.mode.TEST.toString() + ';' + this.$store.state.auth + ';' + (new Date()).getTime()); },
        alarmTestModal() {
            this.$swal({
                title: 'Testowanie alarmu',
                icon: 'success',
                allowOutsideClick: false
            }).then(result => {
                if (result.isConfirmed) {
                    this.$mqtt.publish('mode', this.$store.state.mode.INACTIVE.toString() + ';' + this.$store.state.auth + ';' + (new Date()).getTime());
                }
            });
        }
    }
}
</script>