<template>
    <div :class="{'animate__animated animate__backInLeft': loaded}" rel="view">
        <router-view />
        <footer class="footer">
            <div class="grid footer__grid">
                <div class="footer__logout" v-if="currentRouteName == 'Default'">
                    <button type="button" class="footer__logout__button" @click="logout">
                        <i class="icon-lock"></i>
                    </button>
                </div>
				<div class="uptime" v-if="currentRouteName == 'Default'">
					<ul>
						<li v-for="item in startUpDate">
							{{ item.value }}
							<span>{{ item.title }}</span>
						</li>
					</ul>
				</div>
                <p>
                    Wszelkie prawa zastrzeżone © UNITI GRZEGORZ BANAŚ
                </p>
                <div class="footer__nav" v-if="currentRouteName == 'Default'">
                    <ul class="footer__list">
                        <li>
                            <Calibration />
                        </li>
                        <li>
                            <Test />
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
    <Loader :loaded="loaded" />
    <Updates />
</template>
<script>
import Loader from '@/components/Loader';
import Calibration from '@/components/Calibration';
import Test from '@/components/Test';
import Updates from '@/components/Updates';

export default {
    components: { Calibration, Test, Loader, Updates },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },

		startUpDate() {
			if (this.$store.state.uptime === 0) {
				return {};
			}

			/*
			const timestamp = new Date().getTime(),
				start_up = new Date(timestamp - this.$store.state.uptime);

			const formatDate = (date) => {
				let datePart = [date.getDate(), date.getMonth() + 1, date.getFullYear()]
					.map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, '0')).join('.');
				let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
					.map((n, i) => n.toString().padStart(2, '0')).join(':');
				return `${datePart} ${timePart}`;
			}

			return formatDate(start_up);
			*/

			const date = new Date(this.$store.state.uptime);

			return [
				{
					title: 'Dni',
					value: date.getUTCDate() - 1
				}, {
					title: 'Godzin',
					value: date.getUTCHours(),
				}, {
					title: 'Minuty',
					value: date.getUTCMinutes(),
				}, {
					title: 'Sekundy',
					value: date.getUTCSeconds()
				}
			];
		}
    },
    data() {
        return {
            loaded: false
        }
    },
    methods: {
        logout() {
            this.$swal({
                title: 'Czy na pewno chcesz się wylogować?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#c95450',
                cancelButtonColor: '#222222',
                confirmButtonText: 'Tak, wyloguj mnie',
                cancelButtonText: 'Anuluj'
            }).then((result) => {
                if (result.value) {
                    this.$store.state.auth = -1;
                    localStorage.removeItem('auth');

                    this.$swal({
                        icon: 'success',
                        title: 'Autoryzacja',
                        text: 'Nastąpiło poprawne wylogowanie się z systemu'
                    });

                    this.$router.push({name: 'Authorization'});
                }
            });
        }
    },
    mounted() {
        window.document.body.classList.remove('no-transition');

        this.$mqtt.subscribe('configuration/get');
        this.$mqtt.publish('configuration/request');

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.loaded = false;
                if (!this.$mqtt.isConnected) {
                    this.$mqtt.reconnect();
                }
                this.$mqtt.subscribe('configuration/get');
                this.$mqtt.publish('configuration/request');
            } else {
                this.$mqtt.end(true);
            }
        });
    },
    mqtt: {
        'configuration/get'(data)  {
            this.$mqtt.unsubscribe('configuration/get');
            this.$store.dispatch('load', data);
            this.loaded = true;
        }
    }
}
</script>