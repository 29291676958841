/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register('https://garden.uniti.pl/service-worker.js', {
    message(data) {
        if (data && data.type === 'SKIP_WAITING') {
          self.skipWaiting()
        }
    },
    ready () {
      const version = `version-${process.env.VUE_APP_VERSION}`;
    },
    registered (registration) {
      /*
      setInterval(() => {
        registration.update();
      }, 1000 * 60);
      */
    },
    cached () {},
    updatefound () {},
    updated (registration) {
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline () {},
    error (error) {}
  })
}
