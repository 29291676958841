import { createApp } from 'vue'
import Application from './Application.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Plugin's
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueMqtt from 'vue-mqtt'

import VueCryptojs from 'vue-cryptojs'

// Styles
import './assets/styles/default.less'

createApp(Application)
    .use(store)
    .use(router)
    .use(VueMqtt, 'wss://uniti.pl:9001/mqtt', {
        username: 'website',
        password: 'aHLPOv3UU19xS0m',
        rejectUnauthorized: false,
        clean: true
    })
    .use(VueSweetalert2)
    .use(VueCryptojs)
    .mount('.application')