<template>
<div class="signal_strength">
    {{ signalStrengthText }}
    <ul>
        <li v-for="index in 5" v-bind:class="{'active': this.$store.state.settings.alarm[3] >= index}"></li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'SignalStrength',
    computed: {
        signalStrengthText() {
            switch (this.$store.state.settings.alarm[3]) {
                case 0:
                    return this.text[3];
                case 1:
                    return this.text[2];
                case 2:
                case 3:
                    return this.text[1];
                case 4:
                case 5:
                    return this.text[0];
            }
        }
    },
    data() {
        return {
            text: ['Silne', 'Umiarkowane', 'Słabe', 'Brak']
        }
    }
}
</script>