<template>
<div class="controls">
    <div class="grid controls__grid">
        <h1 class="narrow" @click="subscribe">Urządzenia</h1>
        <ul>
            <li v-bind:class="{'active': this.$store.state.settings.devices[0]}">
                <button @click="change(0)">
                    <i class="controls__icon">
                        <img src="@/assets/images/security-off.png" class="off" />
                        <img src="@/assets/images/security-on.png" class="on" />
                    </i>
                    <label class="button button--small" v-bind:class="{'button--red': !this.$store.state.settings.devices[0]}">
                        <template v-if="this.$store.state.settings.devices[0]">Włączone</template>
                        <template v-else>Wyłączone</template>
                    </label>
                    <h4>
                        Światło na<br />
                        <span class="yellow">ogród</span>
                    </h4>
                </button>
                <div class="loading" v-if="loading[0]"><i></i></div>
            </li>
            <li v-bind:class="{'active': this.$store.state.settings.devices[1]}">
                <button @click="change(1)">
                    <i class="controls__icon">
                        <img src="@/assets/images/colorful-v3-off.png" class="off" />
                        <img src="@/assets/images/colorful-v3-on.png" class="on" />
                    </i>
                    <label class="button button--small" v-bind:class="{'button--red': !this.$store.state.settings.devices[1]}">
                        <template v-if="this.$store.state.settings.devices[1]">Włączone</template>
                        <template v-else>Wyłączone</template>
                    </label>
                    <h4>
                        Światło na<br />
                        <span class="yellow">drzewie</span>
                    </h4>
                </button>
                <div class="loading" v-if="loading[1]"><i></i></div>
            </li>
            <li v-bind:class="{'active': this.$store.state.settings.devices[2]}">
                <button @click="change(2)">
                    <i class="controls__icon">
                        <img src="@/assets/images/lantern-off.png" class="off" />
                        <img src="@/assets/images/lantern-on.png" class="on" />
                    </i>
                    <label class="button button--small" v-bind:class="{'button--red': !this.$store.state.settings.devices[2]}">
                        <template v-if="this.$store.state.settings.devices[2]">Włączone</template>
                        <template v-else>Wyłączone</template>
                    </label>
                    <h4>
                        Latarnia przy<br />
                        <span class="yellow">ognisku</span>
                    </h4>
                </button>
                <div class="loading" v-if="loading[2]"><i></i></div>
            </li>
            <li v-bind:class="{'active': this.$store.state.settings.devices[3]}">
                <button @click="change(3)">
                    <i class="controls__icon">
                        <img src="@/assets/images/lantern-off.png" class="off" />
                        <img src="@/assets/images/lantern-on.png" class="on" />
                    </i>
                    <label class="button button--small" v-bind:class="{'button--red': !this.$store.state.settings.devices[3]}">
                        <template v-if="this.$store.state.settings.devices[3]">Włączone</template>
                        <template v-else>Wyłączone</template>
                    </label>
                    <h4>
                        Latarnia przy<br />
                        <span class="yellow">altance</span>
                    </h4>
                </button>
                <div class="loading" v-if="loading[3]"><i></i></div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: 'Devices',
    mounted() {
        this.$mqtt.subscribe('update-device/#');
    },
    data() {
        return {
            loading: [false, false, false, false]
        }
    },
    mqtt: {
        'update-device/#'(value, topic)  {
            const device = parseInt(topic.substr(14, 1));
            this.loading[device] = false;
            this.$store.state.settings.devices[device] = parseInt(value);
        }
    },
    methods: {
        change(device) {
            this.loading[device] = true;
            this.$mqtt.publish('device/' + device, (~~!this.$store.state.settings.devices[device]).toString());
        }
    }
}
</script>