<template>
<div class="loader" v-bind:class="{'animate__animated animate__backOutRight': loaded}" ref="loader">
    <div class="loader__background">
        <video poster="@/assets/videos/loader.jpg" playsinline muted autoplay loop onloadstart="this.playbackRate = 0.5;">
            <source src="@/assets/videos/loader.mp4" type="video/mp4" />
            <source src="@/assets/videos/loader.webm" type="video/webm" />
        </video>
    </div>
    <div class="loader__wrapper">
        <div>
            <img src="@/assets/images/logotype-uniti.png" />
        </div>
        <h1>
            Garden <sup>2.5</sup>
        </h1>
        <i></i>
        <h1>Trwa łączenie</h1>
        <h2>z modułem GSM</h2>
        <p>
            Jeżeli połączenie trwa zbyt długo,<br />
            zamknij i uruchom ponownie aplikację
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'Loader',
    props: ['loaded'],
    mounted () {},
    watch: {
        loaded: {
            immediate: true,
            handler(value, old) {
                if (value) {
                    document.body.classList.remove('locked');
                    return;
                }

                document.body.classList.add('locked');
            }
        }
    }
}
</script>