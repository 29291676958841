<template></template>

<script>
export default {
    name: 'Updates',
    created() {
        document.addEventListener('swUpdated', this.updateAvailable); // { once: true }

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
        });
    },
    mounted() {},
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false
        }
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail;
            this.updateExists = true;

            this.$swal({
                title: 'Aktualizacja',
                text: 'Została zainstalowana nowa wersja aplikacji',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Odśwież'
            }).then((result) => {
                this.refreshApp();
            });
        },

        refreshApp() {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
    }
}
</script>